import React from 'react'
import { FaLinkedin } from 'react-icons/fa'
// import { FaLinkedin, FaGithub, FaInstagram, FaTwitter } from 'react-icons/fa'
// https://react-icons.netlify.com/#/icons/fa
// <FaGithub size={48}/>
// <FaInstagram size={48}/>
// <FaTwitter size={48}/>

// Social
const Social = () => {
  return (
    <div className='connect'>
      <a href='https://www.linkedin.com/in/idea34/' target='_blank' rel='noopener noreferrer'>
        <FaLinkedin size={48} />
      </a>
    </div>
  )
}
export default Social
