import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

// set types
const propTypes = {
  cssClassImage: PropTypes.string
}

// set defaults
const defaultProps = {
  defaultCSSImage: 'img-fluid'
}

const Image = (props) => {
  const
    {
      file,
      height,
      width
    } = props

  // classes to be added
  const classes = classNames(
    props.cssClassImage,
    defaultProps.defaultCSSImage
  )

  return (
    <img className={classes} src={file} height={height} width={width} />
  )
}

Image.propTypes = propTypes
Image.defaultProps = defaultProps

export default Image
