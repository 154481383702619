import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import TileLink from './Click'

// set types
const propTypes = {
  cssClass: PropTypes.string
}

// set defaults
const defaultProps = {
  cssClass: 'tile d-flex flex-fill align-items-center justify-content-center'
}

// tile
const Tile = (props) => {
  // classes to be added
  const classes = classNames(
    props.cssClassTile,
    defaultProps.cssClass
  )

  return (
    <div className={classes}>
      <TileLink {...props} cssClassClick='tile-linked'>
        {props.children}
      </TileLink>
    </div>
  )
}

Tile.propTypes = propTypes
Tile.defaultProps = defaultProps

export default Tile
