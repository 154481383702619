import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

// set types
const propTypes = {
  cssClassClick: PropTypes.string
}

// set defaults
const defaultProps = {
  defaultCSSClick: 'ux-component-click'
}

const Click = (props) => {
  const
    {
      link,
      href,
      target
    } = props

  // classes to be added
  const classes = classNames(
    props.cssClassClick,
    defaultProps.defaultCSSClick
  )

  function RenderLink (props) {
    const hasLink = props.link
    const hasHref = props.href

    if (hasLink) {
      return (
        <Link className={classes} to={link}>
          {props.children}
        </Link>
      )
    } else if (hasHref) {
      return (
        <a className={classes} href={href} target={target}>
          {props.children}
        </a>
      )
    } else {
      return (
        <>{props.children}</>
      )
    }
  }

  return (
    RenderLink(props)
  )
}

Click.propTypes = propTypes
Click.defaultProps = defaultProps

export default Click
