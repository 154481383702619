import React from 'react'
import Tile from './Tile'
import Image from './Image'
import Social from './Social'
import Figure from './Figure'

import idea34 from '../images/id/idea34_white.svg'
import mike from '../images/photos/mw.jpg'
import team from '../images/photos/team.jpg'
import speaking from '../images/photos/speaking.jpg'
import perkinelmer from '../images/id/pki.svg'
import adidas from '../images/id/Adidas.svg'
import ibm from '../images/id/ibm.svg'
import psa from '../images/id/psui.png'

const Home = () => (

  <div className='grid d-flex flex-wrap vh-100 tile-wrap'>

    <Tile cssClassTile='background-blue'>
      <h1 className='text-large'>Create</h1>
    </Tile>

    <Tile cssClassTile='background-light tile-half-horizontal'>
      <h1 className='text-large'>UX</h1>
    </Tile>

    <Tile cssClassTile='background-green tile-half-horizontal p-3'>
      <Image cssClass='w-100' file={mike} />
    </Tile>

    <Tile cssClassTile='background-purple'>
      <h1 className='text-large'>Design</h1>
    </Tile>

    <Tile cssClassTile='background-dark tile-half-vertical'>
      <Image cssClassImage='w-75 p-3' file={idea34} />
    </Tile>

    <Tile cssClassTile='background-green tile-quarter'>
      <h2 className='text-medium'>Learn</h2>
    </Tile>

    <Tile cssClassTile='background-blue tile-quarter p-3'>
      <Image cssClassImage='w-100' file={team} />
    </Tile>

    <Tile cssClassTile='background-light tile-half-vertical'>
      <h2 className='text-medium'>Mike<span className='font-light'>Wilson</span></h2>
    </Tile>

    <Tile
      cssClassTile='background-light'
      href='https://www.pistoiaalliance.org/blog/agile-ux-designing-together/'
      target='_BLANK'
    >
      <Figure
        caption='Check out my article with the Pistoia Alliance, '
        captionTitle='Agile UX: Designing Together'
        file={speaking}
        cssClassFigure='p-3'
      />
    </Tile>

    <Tile cssClassTile='background-purple tile-half-horizontal'>
      <Image cssClassImage='w-75 p-3' file={perkinelmer} />
    </Tile>

    <Tile cssClassTile='background-dark tile-half-horizontal'>
      <Social />
      <h3 className='mt-3'>connect</h3>
    </Tile>

    <Tile cssClassTile='background-green'>
      <h1 className='text-large'>build</h1>
    </Tile>

    <Tile cssClassTile='background-green tile-half-vertical'>
      <h2 className='text-medium'>share</h2>
    </Tile>

    <Tile cssClassTile='background-dark tile-quarter'>
      <Image cssClassImage='w-50 p-3' file={adidas} />
    </Tile>

    <Tile cssClassTile='background-light tile-quarter'>
      <Image cssClassImage='w-50 p-3' file={ibm} />
    </Tile>

    <Tile cssClassTile='background-blue tile-half-vertical'>
      <h2 className='text-medium'>
        <Image width='48' file={psa} />
        coach
      </h2>
      <small>
          Certified Professional User Experience by scrum.org
      </small>
    </Tile>

  </div>

)

export default Home
