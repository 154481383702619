import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Image from './Image'
import Click from './Click'

// set types
const propTypes = {
  children: PropTypes.node,
  cssClassFigure: PropTypes.string,
  cssClassCaption: PropTypes.string,
  caption: PropTypes.string,
  captionTitle: PropTypes.string
}

// set defaults
const defaultProps = {
  defaultCssFigure: 'fig',
  defaultCssCaption: 'fig-caption',
  caption: '',
  captionTitle: ''
}

const Figure = (props) => {
  const
    {
      caption,
      captionTitle
    } = props

  // classes to be added
  const figureClasses = classNames(
    props.cssClassFigure,
    defaultProps.defaultCssFigure
  )

  const captionClasses = classNames(
    props.cssClassCaption,
    defaultProps.defaultCssCaption
  )

  return (
    <figure className={figureClasses}>
      <div className='fig-image'>
        <Click {...props}>
          <Image {...props} />
        </Click>
      </div>
      <figcaption className={captionClasses}>
        <Click {...props}>
          <h5>{caption}</h5>
          <h4 className='fig-caption-title'>{captionTitle}</h4>
        </Click>
      </figcaption>
    </figure>
  )
}

Figure.propTypes = propTypes
Figure.defaultProps = defaultProps

export default Figure
